import { Contract } from 'web3-eth-contract';
import store from '@/store';
import { GetterCommands } from '@/store/getters';
import NftDetail from '@/common/contracts/abis/nft/nft-detail';

export function getNftDetailContract(): Contract | null {
  const web3 = store.getters[GetterCommands.GET_WEB3];
  if (!web3) {
    return null;
  }
  console.log(NftDetail.getNFTContractAddress());
  return new web3.eth.Contract(NftDetail.ABI, NftDetail.getNFTContractAddress());
}

export function getUriByNFTId(nftId: string): string {
  const contract = getNftDetailContract();
  if (contract === null) {
    return '';
  }
  return contract.methods.uri(nftId).call();
}

// nft transfer
export function transfer(
  account: string,
  to: string,
  id: string,
  amount: number,
  data = '0x00',
): Promise<boolean> {
  const contract = getNftDetailContract();
  if (contract === null) {
    return Promise.reject();
  }
  return contract.methods.safeTransferFrom(account, to, id, amount, data).send({ from: account });
}
